import React, { FC, useEffect, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { loginEmail, loginFacebook, loginGoogle } from "../../api/login";
import { useAuth } from "../../providers/AuthProvider";
// import FacebookLogin, {
//   ProfileSuccessResponse,
// } from "@greatsumini/react-facebook-login";
import SignInWithEmailForm from "./SignInWithEmailForm";
import ForgotPasswordContainer from "../reset_password/ForgotPasswordContainer";
import { logger as Logger, LogLevel } from "../../services/Logger";
import { Helmet } from "react-helmet-async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";

interface SignInProps {}

const SignIn: FC<SignInProps> = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [loginFailed, setLoginFailed] = useState<boolean>(false);
  const [error, setError] = useState("");

  // const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID as string;

  const handleGoogleLogin = async (code: string) => {
    try {
      const { accessToken, refreshToken, user, lessons } = await loginGoogle(
        code
      );

      Logger.log(LogLevel.INFO, "Successful Google login:", {
        accessToken,
        refreshToken,
        user,
        lessons,
      });

      // Use the login method from AuthProvider to update authentication state
      if (accessToken && user) {
        login(user, {
          access_token: accessToken,
          refresh_token: refreshToken,
        });
        if (lessons && lessons.length > 0) {
          navigate("/my-lessons");
        } else {
          navigate("/learn-song-list");
        }
      } else {
        Logger.error("Google login failed: Access token or user not available");
      }
    } catch (error: any) {
      if (error.response && error.response.data) {
        const responseData = error.response.data;
        const errorMessage = (responseData as { message: string }).message;
        console.error("Request failed:", errorMessage);
        setError(errorMessage);
      } else {
        console.error("Sign up failed:", error);
      }
    }
  };

  const onClickLoginGoogle = useGoogleLogin({
    onError: (error) => {
      Logger.error("Google onError:", error);
    },
    onSuccess: (codeResponse) => {
      // console.log(codeResponse);
      handleGoogleLogin(codeResponse.code);
    },
    flow: "auth-code",
    scope: "openid email profile",
    // ux_mode: "redirect",
    // redirect_uri: `${window.location.origin}/sign-in`,
  });

  // const onClickFacebook = async (response: ProfileSuccessResponse) => {
  //   try {
  //     const { accessToken, refreshToken, user } = await loginFacebook(response);

  //     if (accessToken && user) {
  //       login(user, { access_token: accessToken, refresh_token: refreshToken });
  //       navigate("/my-lessons");
  //     } else {
  //       Logger.error(
  //         "Facebook login failed: Access token or user not available"
  //       );
  //     }
  //   } catch (error) {
  //     Logger.error("Facebook login failed:", error);
  //   }
  // };

  const onClickSignInWithEmail = async (data: {
    email: string;
    password: string;
  }) => {
    try {
      const { accessToken, user, lessons } = await loginEmail(
        data.email,
        data.password
      );
      login(user, { access_token: accessToken, refresh_token: "" });
      if (lessons && lessons.length > 0) {
        navigate("/my-lessons");
      } else {
        navigate("/learn-song-list");
      }
    } catch (error) {
      Logger.error("Email sign in failed:", error);
      setLoginFailed(true);
    }
  };

  const onForgotPasswordClick = () => {
    setShowForgotPassword(true); // Set the state to show the ForgotPassword component
  };

  const handleForgotPasswordClose = () => {
    setShowForgotPassword(false); // Close the ForgotPassword component
  };

  const onSignUpTextClick = useCallback(() => {
    navigate("/sign-up");
  }, [navigate]);

  return (
    <>
      {/* Helmet component to dynamically set the page title */}
      <Helmet>
        <title>{"Sign in"}</title>
      </Helmet>

      <div className="login-bgs">
        <div className="container-gbc pt-3 pb-4 py-md-5">
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-md-6 col-lg-5 col-xl-4 col-xxl-3">
              <div className="rounded-5 p-3 p-md-4 my-4 card-songs">
                {/* Warning */}
                {error && <div style={{ color: "red" }}>{error}</div>}
                {showForgotPassword ? (
                  <ForgotPasswordContainer
                    onClose={handleForgotPasswordClose}
                  />
                ) : (
                  <div className="sign-up">
                    <h3 className="fw-bold fs-1 mb-0">Welcome!</h3>
                    <p className="fs-gbc-2 fw-bold mb-0">
                      Log in to your account.
                    </p>

                    <SignInWithEmailForm
                      continue={onClickSignInWithEmail}
                      onForgotPasswordClick={onForgotPasswordClick}
                      clearErrorMessage={() => setLoginFailed(false)}
                    />
                    {loginFailed && (
                      <div className="text-danger mt-2">
                        Login failed. Please try again.
                      </div>
                    )}
                    <div className="d-flex gap-3 mt-3 align-items-center justify-content-between">
                      {/* <FacebookLogin
                        appId="6022926104498630"
                        fields="first_name,last_name,email,picture"
                        onSuccess={(response) => {
                          console.log("Login Success!", response);
                        }}
                        onFail={(error) => {
                          console.log("Login Failed!", error);
                        }}
                        onProfileSuccess={(response) => {
                          console.log("Get Profile Success!", response);
                          onClickFacebook(response);
                        }}
                        render={({ onClick, logout }) => (
                          <button className="bttn" onClick={onClick}>
                            <i className="me-2 fa-brands fa-facebook-f"></i>{" "}
                            Facebook
                          </button>
                        )}
                      /> */}
                      <button
                        className="d-flex gap-2 align-items-center justify-content-center bttn bttn-stroke-yellow w-100 "
                        onClick={onClickLoginGoogle}
                      >
                        Log in with{" "}
                        <div>
                          <FontAwesomeIcon icon={faGoogle} className="me-2" />{" "}
                          <span className="fw-bold">Google</span>
                        </div>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-5 col-xl-4 col-xxl-3">
              <div className="rounded-5 p-4 pb-3 pb-md-4 bg-white border border-1 text-center">
                <p className="text-black fs-gbc-2 fw-bold">
                  Not a member yet?{" "}
                </p>
                <button className="bttn" onClick={onSignUpTextClick}>
                  Create an account
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
