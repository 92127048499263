import { FunctionComponent, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../providers/AuthProvider";
import BattleTabs from "./components/BattleTabs";
import { BattleTabType } from "./types/BattleTabType";
import BattleCard from "./components/BattleCard";
import { getMyBattles } from "../../api/battles";
import { Battle } from "../../models/Battle";
import { Helmet } from "react-helmet-async";
import FindOpponent from "../find_opponent/FindOpponent";

const MyBattles: FunctionComponent = () => {
  const navigate = useNavigate();
  const { authState } = useAuth();
  const { tokens } = authState;
  const [battlesActive, setBattlesActive] = useState<Battle[]>([]);
  const [battlesFinished, setBattlesFinished] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [selectedMainTab, setSelectedMainTab] = useState("BATTLES");

  const userId = authState.user?.id; // Access the logged-in user's ID from authState

  // Determine the selected tab based on the URL path
  const selectedTab = location.pathname.includes("active")
    ? "ACTIVE"
    : location.pathname.includes("finished")
    ? "FINISHED"
    : location.pathname.includes("find-opponents")
    ? "FIND_OPPONENTS"
    : "ACTIVE";

  const fetchBattles = async () => {
    try {
      const accessToken = tokens?.access_token || "";
      if (selectedTab === "ACTIVE") {
        const myActiveBattles: Battle[] = await getMyBattles(
          accessToken,
          "active"
        );
        setBattlesActive(myActiveBattles);
      } else {
        const myActiveBattles: Battle[] = await getMyBattles(
          accessToken,
          "finished"
        );
        setBattlesFinished(myActiveBattles);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(
        error instanceof Error ? error : new Error("Failed to fetch my battles")
      );
    }
  };

  useEffect(() => {
    fetchBattles();
  }, [selectedTab, tokens]);

  // Handle tab change and update URL path
  const handleTabChange = (tab: string) => {
    if (tab === "ACTIVE") {
      navigate("/my-battles/active");
    } else if (tab === "FINISHED") {
      navigate("/my-battles/finished");
    } else if (tab === "FIND_OPPONENTS") {
      navigate("/my-battles/find-opponents");
    }
  };

  const onBattleClick = (battle: Battle) => {
    navigate(`/battles/${battle.id}`);
  };

  return (
    <>
      {/* Helmet component to dynamically set the page title */}
      <Helmet>
        <title>{"My battles"}</title>
      </Helmet>

      <div className="bg-yellow pt-5">
        <div className="container-gbc pt-5 text-black">
          <div className="row text-center pt-5">
            <div className="col-12">
              <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                <h2 className="fw-normal fs-gbc-1 mb-2">My battles</h2>
                <p className="fs-gbc-2 fw-lighter mb-0 col-md-10 col-lg-6">
                  Here you will find all your active and finished battles.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-green">
        <BattleTabs onTabChange={handleTabChange} selectedTab={selectedTab} />

        <div className="container-gbc py-4 py-md-5 position-relative z-1">
          <div className="row justify-content-center align-items-center">
            {loading && (
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
            {!loading &&
              selectedTab === "ACTIVE" &&
              battlesActive.length === 0 && (
                <div className="text-center">No active battles yet.</div>
              )}
            {!loading &&
              selectedTab === "FINISHED" &&
              battlesFinished.length === 0 && (
                <div className="text-center">No finished battles yet.</div>
              )}
            {selectedTab === "ACTIVE" &&
              battlesActive.map((activeBattle) => (
                <BattleCard
                  key={activeBattle.id}
                  battle={activeBattle}
                  type="ACTIVE"
                  onBattleClick={onBattleClick}
                  currentUserId={userId}
                />
              ))}
            {selectedTab === "FINISHED" &&
              battlesFinished.map((finishedBattle) => (
                <BattleCard
                  key={finishedBattle.id}
                  battle={finishedBattle}
                  type="FINISHED"
                  onBattleClick={onBattleClick}
                  currentUserId={userId}
                />
              ))}
            {selectedTab === "FIND_OPPONENTS" && <FindOpponent />}
          </div>
        </div>
      </div>
    </>
  );
};

export default MyBattles;
