// src/utility/shareTitleGenerator.ts
import { formatDate } from "./../../utility/DateFormatter"; // Adjust the import path as necessary
import { Battle } from "../../models/Battle"; // Adjust the import path as necessary

export const generateSocialShareTitle = (
  battle: Battle | null | undefined
): string => {
  if (!battle) {
    return "Epic Guitar Battle | Guitar Battle Club";
  }

  if (battle.endedAt != null) {
    return `Epic Guitar Battle – Ended at ${formatDate(
      new Date(battle.endedAt)
    )} | Guitar Battle Club`;
  }

  return `Epic Guitar Battle – Vote by ${formatDate(
    new Date(battle.endedAt)
  )} | Guitar Battle Club`;
};
