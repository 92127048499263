import React, { useState, useCallback } from "react";
import Metronome from "../codepen/Metronome";
import { getSongPdf } from "../../api/songs";
import { useAuth } from "../../providers/AuthProvider";
import { LearnSongsCardType } from "./LearnSongsCard";
import AWSVideoPlayerForm from "../components/AWSVideoPlayerForm";
import Accordion from "../components/Accordion";  // Import your Accordion component
import { apiBaseUrl } from "../../api/config/apiConfig";

interface SongToolsCardProps {
  song: LearnSongsCardType;
}

const SongToolsCard: React.FC<SongToolsCardProps> = ({ song }) => {
  const { authState } = useAuth();
  const { tokens } = authState;
  const tipsVideoThumbnail = `${apiBaseUrl}/videos/thumbnail?filePath=${song.tips ? song.tips.replace(".mp4", "_thumbnail.png") : ""}`;

  const onDownloadTabsClick = useCallback(
    async (pdfUrl?: string) => {
      if (!pdfUrl) {
        console.error("No PDF URL provided");
        return;
      }

      try {
        const accessToken = tokens?.access_token || "";
        const signedUrl = await getSongPdf(accessToken, pdfUrl);
        window.open(signedUrl, "_blank");
      } catch (error) {
        return;
      }
    },
    [tokens]
  );

  // Define the accordion items
  const accordionItems = [
    {
      id: 1,
      title: 'Tabs',
      content: song.pdfUrl ? (
        <button
          onClick={() => onDownloadTabsClick(song.pdfUrl)}
          className="bttn bttn-smaller"
          role="button"
        >
          Download Tabs
        </button>
      ) : (
        <p>No tabs available.</p>
      ),  // JSX.Element
    },
    {
      id: 2,
      title: 'Backing Track',
      content: song.backingTrack ? (
        <AWSVideoPlayerForm
          videoUrl={song.backingTrack}
          isPrivate={true}
        />
      ) : (
        <p className="mb-0">No backing track available.</p>
      ),  // JSX.Element
    },
    {
      id: 3,
      title: 'Metronome',
      content: <Metronome />,  // JSX.Element
    },
    {
      id: 4,
      title: 'Tips',
      content: song.tips ? (
        <AWSVideoPlayerForm
          videoUrl={song.tips}
          isPrivate={true} 
          thumbnailUrl={tipsVideoThumbnail}/>
      ) : (
        <p className="mb-0">There are no tips for now!</p>
      ),
    },
  ];

  return (
    <div className="d-flex flex-column gap-3 gap-md-4 justify-content-center mt-4">
      {/* Render the Accordion with dynamic content */}
      <Accordion items={accordionItems} />
    </div>
  );
};

export default SongToolsCard;
