import { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCog,
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";

const AudioContext = window.AudioContext;

const Metronome = () => {
  const [bpm, setBpm] = useState(60);
  const [timerValue, setTimerValue] = useState(60);
  const [counting, setCounting] = useState(false);
  const [accentPitch, setAccentPitch] = useState(380);
  const [offBeatPitch, setOffBeatPitch] = useState(200);
  const [timeSignature, setTimeSignature] = useState(4);
  const [noteCount, setNoteCount] = useState(0);
  var count = 0;
  const [dots, setDots] = useState(Array(4).fill(false));
  const [playing, setPlaying] = useState(false);
  const [stateOfMenu, setStateOfMenu] = useState(false);
  const deltaRef = useRef(0);
  const curTimeRef = useRef(0.0);
  const audioContextRef = useRef<AudioContext>(new AudioContext());
  const timerRef = useRef<number>();
  const [timerChecked, setTimerChecked] = useState(false);

  const handleTimerChange = () => {
    setTimerChecked(!timerChecked);
  };

  useEffect(() => {
    const audioContext = new AudioContext();
    audioContextRef.current = audioContext;
    setDots(Array(timeSignature).fill(false));
  }, [timeSignature]);

  useEffect(() => {
    if (counting) {
      const countdownInterval = setInterval(() => {
        setTimerValue((prev) => {
          if (prev <= 1) {
            setCounting(false);
            setPlaying(false);
            clearInterval(countdownInterval);
            return 60;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [counting]);

  useEffect(() => {
    if (playing) {
      schedule();
    } else {
      window.clearInterval(timerRef.current);
    }
  }, [playing]);

  const schedule = () => {
    while (curTimeRef.current < audioContextRef.current.currentTime + 0.1) {
      playNote();
      updateTime();
    }
    timerRef.current = window.setTimeout(schedule, 0.1);
  };

  const updateTime = () => {
    curTimeRef.current += 60.0 / bpm;
    setNoteCount((prev) => {
      return (prev + 1) % timeSignature;
    });
  };

  const playNote = () => {
    const note = audioContextRef.current.createOscillator();
    const currentDot = dots[count];

    note.frequency.value = currentDot ? accentPitch : offBeatPitch;
    note.connect(audioContextRef.current.destination);
    note.start(curTimeRef.current);
    note.stop(curTimeRef.current + 0.05);

    const newDots = Array(4).fill(false);
    newDots[count] = true;
    setDots(newDots);
    count = (count + 1) % timeSignature;
  };

  const handleTapTempo = () => {
    const d = new Date();
    const temp = d.getTime();
    setBpm(Math.ceil(60000 / (temp - deltaRef.current)));
    deltaRef.current = temp;
  };

  const handlePlayPause = () => {
    setPlaying((prev) => {
      if (prev) {
        setCounting(false);
        window.clearInterval(timerRef.current);
        return false;
      } else {
        curTimeRef.current = audioContextRef.current.currentTime;
        setCounting(timerChecked ?? false);
        return true;
      }
    });
  };

  const handleMenu = () => {
    setStateOfMenu(!stateOfMenu);
  };

  return (
    <div className="metronome-gbc">
      <div className="metronome---holder order-2 order-md-2">
        <section className="metronome-container d-flex flex-column">
          <div className="d-flex justify-content-between metronome-header align-items-center p-4">
            <div className="counter">
              {dots.map((dot, index) => (
                <div
                  key={index}
                  className={`dot ${dot ? "active" : ""}`}
                  style={{
                    transform: dot ? "translateY(-10px)" : "none",
                    background: dot ? "#F75454" : "#d3d3d3",
                  }}
                />
              ))}
            </div>
            {/* <h4 className="mb-0 opacity-25">Metronome</h4> */}
            <FontAwesomeIcon
              icon={faCog}
              className="options-btn"
              onClick={handleMenu}
            />
          </div>
          <div className="controls">
            <div className="d-flex justify-content-between flex-wrap">
              <div className="w-auto d-flex flex-column align-items-start gap-2">
                <label>
                  <strong>Bpm:</strong>
                  <span>
                    <FontAwesomeIcon
                      icon={faMinus}
                      className="bpm-minus"
                      onClick={() => setBpm(bpm - 1)}
                    />
                    <input
                      type="text"
                      value={bpm}
                      className="bpm-input form-control"
                      onChange={(e) => {
                        setBpm(parseInt(e.target.value, 10));
                      }}
                    />
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="bpm-plus"
                      onClick={() => setBpm(bpm + 1)}
                    />
                  </span>
                </label>
                <label>
                  <strong>Beat:</strong>
                  <span>
                    <input
                      type="text"
                      value={timeSignature}
                      className="ts-top form-control"
                      onChange={(e) =>
                        setTimeSignature(parseInt(e.target.value, 10))
                      }
                    />
                  </span>
                </label>
                <label className="d-flex align-items-center">
                  <strong>Timer:</strong>
                  <span className="me-2">
                    <input
                      type="text"
                      value={timerValue}
                      className="timer form-control"
                      onChange={(e) =>
                        setTimerValue(parseInt(e.target.value, 10))
                      }
                    />
                  </span>
                  <input
                    type="checkbox"
                    id="timer-check"
                    className="timer-checkbox form-control"
                    checked={timerChecked}
                    onChange={handleTimerChange}
                  />
                  <label htmlFor="timer-check"></label>
                </label>
              </div>
              <div className="d-flex flex-row flex-md-column mt-4 mt-md-0 w-auto gap-2 h-100 align-items-end">
                <button
                  className="tap-btn d-block w-100"
                  onClick={handleTapTempo}
                >
                  Tap
                </button>
                <button
                  className="play-btn d-block w-100"
                  onClick={handlePlayPause}
                >
                  {playing ? "Stop" : "Play"}
                </button>
              </div>
            </div>
          </div>

          <div className={stateOfMenu ? "options options-active" : "options"}>
            <FontAwesomeIcon icon={faCaretDown} className="up" />
            <label>
              Off Beat Pitch:
              <input
                type="range"
                min="0"
                max="500"
                value={offBeatPitch}
                className="beat-range"
                onChange={(e) => setOffBeatPitch(parseInt(e.target.value, 10))}
              />
            </label>
            <label>
              Accent Pitch:
              <input
                type="range"
                min="0"
                max="500"
                value={accentPitch}
                className="accent-range"
                onChange={(e) => setAccentPitch(parseInt(e.target.value, 10))}
              />
            </label>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Metronome;

// import React from "react"; class Metronome extends React.Component {   render() {     const htmlUrl = "https://guitarbattleclub.com/metronome/";     return (       <div className="metronome-gbc">         <iframe           title="Embedded HTML"           src={htmlUrl}           width="100%"           height="255px"           frameBorder="0"           scrolling="auto"         ></iframe>       </div>     );   } } export default Metronome;
