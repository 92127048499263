import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { BattleTabType } from "../types/BattleTabType";

interface Props {
  onTabChange: (tab: BattleTabType) => void;
  selectedTab: BattleTabType;
}

const BattleTabs: React.FC<Props> = ({ onTabChange, selectedTab }) => {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY > 275);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const location = useLocation(); // Get the current location to reflect tab selection

  return (
    <div
      className={
        scroll
          ? "scrolled bg-yellow border-bottom sticky-top-custom"
          : "not-scrolled bg-yellow border-bottom sticky-top-custom"
      }
    >
      <div className="d-flex flex-column justify-content-center align-items-center gap-2">
        <div className="d-flex flex-row flex-md-row gap-1 justify-content-center gap-md-2 flex-wrap px-3">
          <button
            type="button"
            className={` ${
              selectedTab === "ACTIVE"
                ? "bttn bttn-smaller"
                : "bttn bttn-stroke bttn-smaller"
            } `}
            onClick={() => onTabChange("ACTIVE")} // Trigger tab change
          >
            Active battles
          </button>
          <button
            type="button"
            className={` ${
              selectedTab === "FINISHED"
                ? "bttn bttn-smaller"
                : "bttn bttn-stroke bttn-smaller"
            } `}
            onClick={() => onTabChange("FINISHED")} // Trigger tab change
          >
            Finished battles
          </button>
          <button
            type="button"
            className={` ${
              selectedTab === "FIND_OPPONENTS"
                ? "bttn bttn-smaller"
                : "bttn bttn-stroke bttn-smaller"
            } `}
            onClick={() => onTabChange("FIND_OPPONENTS")} // Trigger tab change
          >
            Find opponents
          </button>
        </div>
      </div>
    </div>
  );
};

export default BattleTabs;
